
.chart {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.bar-chart-legend {
  display: inline-block;
  right: 25px;
  float: right;
  padding: 0 20px;
  top: 8px;
  font-size: 10px;
}

.bar-chart-legend .legend-item {
  display: inline-block;
  margin-left: 10px;
}

.bar-chart-legend .legend-color {
  width: 12px;
  height: 12px;
  margin: 3px 5px;
  display: inline-block;
}

/* You can add global styles to this file, and also import other style files */

@import "src/material-icons-helper";
@import "src/variables";
@import "src/custom";
@import 'material-icons/iconfont/material-icons.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  * {
    @apply box-border m-0 p-0;
    font-family: "Exo", sans-serif;
    font-optical-sizing: auto;
  }

  html, body {
    @apply h-screen;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-sigecom-dark-gray;
  }

  input, select, textarea {
    @apply px-2 py-2 text-sm placeholder:text-sm placeholder:text-sigecom-dark-gray;
  }

  input[type="file"] {
    @apply opacity-0 -z-10 absolute;
  }

  input[type="checkbox"] {
    @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-md cursor-pointer accent-sigecom-info;
  }

  input[type="date"] {
    @apply pb-1.5;
  }

  select {
    @apply px-1 cursor-pointer;
  }
}

@layer components {
  .select-search {
    @apply outline-none cursor-pointer focus:ring-1 focus:ring-cbde-primary focus:outline-none w-full rounded h-40;

    option {
      @apply hover:bg-gray-200 mx-1.5;
    }
  }
}

@layer utilities { }

*::-webkit-scrollbar-track {
	-webkit-box-shadow: #c3c3c3;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar {
	width: 5px;
  margin-left: 1rem;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1rem;
	background-color: #c3c3c3;
}

// ::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #9f9e9e;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #ccc;
// }

$mi-sm: 20px;
$mi-md: 30px;
$mi-gr: 40px;

.material-icons {
  font-size: $mi-md;
}

.mi {
  &-1 {
    font-size: $mi-sm !important;
  }
  &-2 {
    font-size: $mi-md !important;
  }
  &-3 {
    font-size: $mi-gr !important;
  }
}
